<template>
  <DashboardCard
    :title="reportType === 'unpaid' ? 'Open Balances' : 'Paid'"
    :count="items.length"
    :loading="loading"
    :widget-type="widgetType"
    :expanded="expanded"
    class="sc-broker-ranking"
    @update:expanded="$emit('update:expanded', $event)"
  >
    <template #actions>
      <v-menu
        v-if="$vuetify.breakpoint.mdAndDown"
        v-model="menu"
        left
        offset-x
        :close-on-content-click="false"
      >
        <template #activator="{ on, attrs }">
          <v-btn v-bind="attrs" icon v-on="on">
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </template>
        <v-card class="pa-2 d-flex gap-2 justify-center flex-column">
          <OpenBalancesDateFilter
            :filter-label.sync="filterLabel"
            :date-from.sync="dateFrom"
            :date-to.sync="dateTo"
            :date-toggle.sync="dateToggle"
            :time-period.sync="timePeriod"
            @click:clear="clearFilter"
            @click:apply="applyFilter"
          />
          <v-text-field
            v-model="keyword"
            outlined
            dense
            placeholder="Search by keyword"
            hide-details
            clearable
          />
          <v-select
            v-if="isAdmin"
            outlined
            label="Select Agent"
            dense
            hide-details
            :items="members"
            item-text="full_name"
            item-value="id"
            clearable
            @change="changeUser"
          />
        </v-card>
      </v-menu>
      <div v-else class="d-flex align-center gap-2">
        <OpenBalancesDateFilter
          :filter-label.sync="filterLabel"
          :date-from.sync="dateFrom"
          :date-to.sync="dateTo"
          :date-toggle.sync="dateToggle"
          :time-period.sync="timePeriod"
          @click:clear="clearFilter"
          @click:apply="applyFilter"
        />
        <v-text-field
          v-model="keyword"
          outlined
          dense
          placeholder="Search by keyword"
          hide-details
          style="max-width: 300px"
          clearable
        />
        <v-select
          v-if="isAdmin"
          outlined
          label="Select Agent"
          dense
          hide-details
          :items="members"
          item-text="full_name"
          item-value="id"
          style="max-width: 200px"
          clearable
          @change="changeUser"
        />
      </div>
    </template>
    <template #content>
      <v-card flat>
        <v-overlay
          :value="loading"
          :opacity=".7"
          absolute
          color="white"
        >
          <div class="text-center">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            />
          </div>
        </v-overlay>
        <OpenBalancesTable
          v-model="pagination"
          :items="items"
          :filter-loading="filterLoading && !loading"
          :search="keyword"
        />
      </v-card>
    </template>
  </DashboardCard>
</template>

<script>
import { memberService } from '@/bundles/Members/factory/memberServiceFactory';
import { ServiceFactory } from '@/services/ServiceFactory';
import { mapState, mapGetters } from 'vuex';
import { debounce } from 'lodash';
import { UserWidgetsEnum } from '@/bundles/Settings/enums/userLayoutEnum';
import { formatDate } from '@/utils/filters';

import DashboardCard from '@/bundles/Dashboard/components/DashboardCard';
import OpenBalancesTable from './OpenBalancesTable.vue';
import OpenBalancesDateFilter from '@/bundles/Dashboard/components/filters/OpenBalancesDateFilter.vue';

const DealsService = ServiceFactory.get('deals');

export default {
  name: 'BrokerProductionCard',

  components: {
    OpenBalancesDateFilter,
    DashboardCard,
    OpenBalancesTable
  },

  props: {
    height: {
      type: [Number],
      default: 200
    },
    reportType: {
      type: String,
      default: 'unpaid'
    },
    expanded: Boolean,
  },

  data: () => ({
    items: [],
    pagination: {
      sortBy: ['commission'],
      sortDesc: [true],
    },
    loading: true,
    filterLoading: false,
    keyword: '',
    members: [],
    memberId: null,
    dateToggle: 'all',
    timePeriod: null,
    timePeriodItems: [
      { text: 'This Calendar Month', value: 'month' },
      { text: 'This Calendar Year', value: 'year' },
      { text: 'Date Range', value: 'date_range' },
    ],
    filterLabel: null,
    dateFrom: null,
    dateTo: null,
    menu: false,
  }),

  computed: {
    ...mapState({
      token: (state) => state.idToken,
    }),
    ...mapGetters('Filters', [
      'getFilterByType',
      'getKeywordByType',
      'getSavedSetId'
    ]),
    filters: function () {
      return this.getFilterByType('eblast');
    },
    isAdmin: function () {
      return this.$store.state.userRoles.includes('super admin') || this.$store.state.userRoles.includes('financial admin');
    },
    formattedFilters: function () {
      return Object.keys(this.filters).map((filter) => ({
        name: filter,
        value: this.filters[filter],
      }));
    },
    widgetType () {
      return UserWidgetsEnum.open_balances;
    }
  },

  watch: {
    keyword: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchWrapperDebounced()
      }
    },
    timePeriod: function (newValue, oldValue) {
      if (newValue !== oldValue && newValue === null) {
        this.dateToggle = null;
        this.dateFrom = null;
        this.dateTo = null;
      }
    }
  },

  created: async function () {
    await this.fetchWrapper();
    if (this.isAdmin) {
      await this.getMembers();
    }
  },

  methods: {
    async changeUser (value) {
      this.memberId = value;
      await this.fetchWrapper();
    },
    async getMembers () {
      const params = [
        {
          name: 'take',
          value: 0,
        },
        {
          name: 'status',
          value: 'active',
        },
      ]
      const response = await memberService.list(params);
      const { data: records } = response
      records.map(item => {
        item.full_name = `${item.profile.first_name} ${item.profile.last_name}`
      })
      records.sort((a, b) => (a.profile.last_name > b.profile.last_name ? 1 : -1))
      this.members = records
    },
    fetchWrapperDebounced: debounce(function () {
      this.fetchWrapper();
    }, 300),
    /**
     * Wrapper for the fetch function
     * */
    fetchWrapper: async function () {
      try {
        this.filterLoading = true;
        const wrapper = document.querySelector('#common-data-table .v-data-table__wrapper');
        if (wrapper) {
          document.querySelector('#common-data-table .v-data-table__wrapper').scrollTop = 0;
        }

        const params = [
          { name: 'sort', value: this.pagination.sortBy, },
          { name: 'descending', value: this.pagination.sortDesc, },
          { name: 'reportType', value: this.reportType },
          ...this.formattedFilters,
        ]

        if (this.keyword) {
          params.push({ name: 'keyword', value: encodeURIComponent(this.keyword) })
        }
        if (this.getSavedSetId('eblast')) {
          params.push({ name: 'savedSet', value: this.getSavedSetId('eblast') });
        }
        if (this.isAdmin && this.memberId) {
          params.push({ name: 'member', value: this.memberId });
        }

        if (this.timePeriod) {
          params.push({ name: 'timePeriod', value: this.timePeriod });
        }

        if (this.timePeriod === 'date_range') {
          if (this.dateFrom) {
            params.push({ name: 'dateFrom', value: this.dateFrom });
          }
          if (this.dateTo) {
            params.push({ name: 'dateTo', value: this.dateTo });
          }
        }

        if (this.dateToggle && this.dateToggle !== 'all') {
          params.push({ name: 'dateToggle', value: this.dateToggle });
        }

        const response = await this.fetch({
          token: this.token,
          params
        });

        const { result: records } = response;
        this.setData({ records });
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.loading = false;
        this.filterLoading = false;
      }
    },
    fetch: async function ({
       token,
       params,
       onDownloadEventHandler = null,
    }) {
      try {
        const { data } = await DealsService.brokerDeals({
          token,
          params,
          onDownloadEventHandler,
        });

        return data;
      } catch (error) {
        const notification = { ...error };
        this.addNotification(notification);
      }
    },
    /**
     * Set data to vue instance
     * @param {array} records
     * */
    setData: function ({ records }) {
      this.items = records.map((item, index) => {
        item['number'] = index + 1;
        return item;
      });
    },
    applyFilter: function () {
      this.menu = false;
      this.fetchWrapper();
      this.setFilterLabel();
    },
    clearFilter: function () {
      this.dateToggle = 'all';
      this.timePeriod = null;
      this.dateFrom = null;
      this.dateTo = null;
    },
    setFilterLabel: function () {
      let label = '';
      if (this.timePeriod && this.timePeriod !== 'date_range') {
        label += `${this.timePeriod === 'month' ? 'This Calendar Month' : 'This Calendar Year'}`;
      } else if (this.timePeriod) {
        label += this.getFormattedDateRange();
      }
      if (this.dateToggle && this.dateToggle !== 'all') {
        label += `${this.dateToggle === 'paid' ? ' - By Paid Date' : ' - By Due Date' }`;
      }
      this.filterLabel = label.length ? label : null;
    },
    getFormattedDateRange () {
      if (this.dateFrom && this.dateTo) {
        return `(${formatDate(this.dateFrom)} - ${formatDate(this.dateTo)})`;
      }

      if (this.dateFrom) {
        return `From: ${formatDate(this.dateFrom)}`;
      }

      if (this.dateTo) {
        return `To: ${formatDate(this.dateTo)}`;
      }

      return '';
    }
  }
};
</script>
